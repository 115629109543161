import React from 'react'
import { graphql, navigate } from 'gatsby'

import { PageLayout } from '@/components/PageLayout'
import { Head } from '@/components/head'
import { tempNewsList } from '@/constants/news'
import { NewsType } from '@/typedir/types'
import { NewsList } from '@/components/common/news'

export const query = graphql`
  query {
    allMicrocmsNews {
      edges {
        node {
          date
          text
          url
        }
      }
    }
  }
`

type NewsData = {
  allMicrocmsNews: {
    edges: { node: NewsType }[]
  }
}

const isNewsData = (data: unknown): data is NewsData => {
  return true
}

export default function News({ data }: { data: unknown }): JSX.Element {
  let newsList: NewsType[] = []
  if (isNewsData(data)) {
    newsList = data.allMicrocmsNews.edges.map((news) => {
      const { date, text, url } = news.node
      const formattedDate = date
        .slice(0, 10)
        .replace('-', '.')
        .replace('-', '.')
      return { date: formattedDate, text, url }
    })
  } else {
    navigate('/404')
  }

  return (
    <>
      <Head title="お知らせ| 株式会社Relicホールディングス" url="news" />
      <PageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="/NEWS"
        breadcrumbsPath="/news"
      >
        <NewsList newsList={[...tempNewsList, ...newsList]} isPage />
      </PageLayout>
    </>
  )
}
